/* eslint-disable react/prop-types */
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
    [theme.breakpoints.only('sm')]: {
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
    },
    [theme.breakpoints.up('md')]: {
      maxWidth: '896px',
    },
    [theme.breakpoints.up('lg')]: {
      maxWidth: '1216px',
    },
    flexGrow: 1,
    margin: '0 auto',
    position: 'relative',
    width: 'auto',
  },
}));

function Section({ children, className }) {
  const classes = useStyles();
  return (
        <section className={`${classes.container} ${className || ''}`}>
            {children}
        </section>
  );
}

export default Section;
