/* eslint-disable consistent-return */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React from 'react';
import { Helmet } from 'react-helmet';
import { withPrefix } from 'gatsby';
import CookieConsent from 'react-cookie-consent';
import useSiteMetadata from '../utils/SiteMetadata';
import CookieFooter from '../components/CookieFooter/CookieFooter';

const TemplateWrapper = ({ children }) => {
  const { title, description } = useSiteMetadata();

  // const DisplayCookieFooter = () => {
  //   if (typeof window !== 'undefined' && window) {
  //     const acceptedCP = localStorage.getItem('acceptedCP');
  //     const navigator = () => {
  //       // navigate to new page with cookie policy
  //       console.debug('navigator clicked');
  //     };
  //     const space = '  ';
  //     const acceptCookie = async () => {
  //       try {
  //         const body = { 'custom:acceptCookies': 'true' };
  //         localStorage.setItem('acceptedCP', true);
  //       } catch (error) {
  //         console.error('error accepting cookie', error);
  //       }
  //     };
  //     const rejectCookie = () => {
  //       console.debug('Accepted Cookies');
  //     };
  //     if (!acceptedCP) {
  //       return (
  //         <React>
  //         <CookieFooter
  //           url={'/sampleURL'}
  //           navigator={navigator}
  //           acceptCookie={acceptCookie}
  //           rejectCookie={rejectCookie}>
  //           {`This website uses only essential cookies in order to provide you with the best possible experience.
  //           You can find all relevant information by reading our ${space} `}
  //         </CookieFooter>
  //         </React>
  //       );
  //     }
  //   }
  // };

  return (
    <div>
      <Helmet>
        <html lang="en" />
        <title>{title}</title>
        <meta name="description" content={description} />

        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href={`${withPrefix('/')}img/newAppIcon.jpg`}
        />
        <link
          rel="icon"
          type="image/png"
          href={`${withPrefix('/')}img/favicon.png`}
          sizes="32x32"
        />
        <link
          rel="icon"
          type="image/png"
          href={`${withPrefix('/')}img/favicon.png`}
          sizes="16x16"
        />
        <link
          rel="mask-icon"
          href={`${withPrefix('/')}img/TrustAliceIcon.png`}
          color="#ff4400"
        />
        <meta name="theme-color" content="#fff" />

        <meta property="og:type" content="business.business" />
        <meta property="og:title" content={title} />
        <meta property="og:url" content="/" />
        <meta
          property="og:image"
          content={`${withPrefix('/')}img/TrustAliceIcon.png`}
        />
        
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:site" content='@TrustAlice1' />
        <meta property="twitter:url" content={`${withPrefix('/')}trustalice.com`} />
        <meta property="twitter:title" content="TrustAlice" />
        <meta property="twitter:description" content="Providing trust assurance for your customers when you reach out to them" />
        <meta property="twitter:image" content={`${withPrefix('/')}img/TrustAliceIcon.png`} />


      </Helmet>
        <div>{children}</div>
        {/* {DisplayCookieFooter()} */}
        <CookieConsent
         location='bottom'
         style={{ background: '#00a9e1', textAlign: 'center', fontSize: '16px' }}
         buttonStyle={{
           background: '#FFF',
           color: '#295BA3',
           fontSize: '16px',
           borderRadius: '7px',
         }}
         expires={365}
         >
          This website uses cookies to ensure you get the best experience on our website.
          <a href='/cookies' style={{ color: '#FFF', textDecoration: 'underline' }} > Learn more.</a>
        </CookieConsent>
    </div>
  );
};

export default TemplateWrapper;
