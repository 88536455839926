import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {
  IconButton, AppBar, Toolbar, Typography, Link, Button,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  appBar: {
    top: 'auto',
    bottom: 0,
    padding: theme.spacing(),
  },
  toolbar: {
    minHeight: '40px',
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    flexDirection: 'row',
    marginRight: '10px',
  },
  footer: {
    position: 'absolute',
    right: '0',
    bottom: '0',
    left: '0',
    backgroundColor: '#9a0606',
    padding: '1rem',
    textAlign: 'center',
  },
  closeButton: {
    position: 'absolute',
    right: 0,
    top: 0,
  },
  link: {
    color: 'white',
    textDecoration: 'underline',
  },
}));

export default function CookieFooter({
  children, navigator, acceptCookie,
}) {
  const classes = useStyles();
  const [cookiePrompt, setCookiePrompt] = useState('true');

  return (
    cookiePrompt
    && <React.Fragment>
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar variant="dense" className={classes.toolbar}>
          <div>
            <Typography variant="h7" color="inherit">
              {children}
            </Typography>
            <Link href="/cookies" onClick={navigator} className={classes.link}>
              Cookie Statement.
          </Link>
          </div>
          <div>
            <Button
              variant='contained'
              backgroundColor='inherit' size='small'
              onClick={() => {
                acceptCookie();
                setCookiePrompt(false);
              }}>Accept</Button>
          </div>
        </Toolbar>
        <IconButton className={classes.closeButton} onClick={() => { setCookiePrompt(false); }}>
          <Close />
        </IconButton>
      </AppBar>
    </React.Fragment>
  );
}

CookieFooter.propTypes = {
  children: PropTypes.string,
  url: PropTypes.string,
  navigator: PropTypes.func,
  acceptCookie: PropTypes.func,
  rejectCookie: PropTypes.func,
};
